const iconNames = {
  FeatureArea: 'FeatureArea',
  FeatureAreaExternal: 'FeatureAreaExternal',
  FeatureAreaInternal: 'FeatureAreaInternal',
  FeatureAreaTotal: 'FeatureAreaTotal',
  FeatureBalcony: 'FeatureBalcony',
  FeatureBanquetDiningSpace: 'FeatureBanquetDiningSpace',
  FeatureBar: 'FeatureBar',
  FeatureBarbecue: 'FeatureBarbecue',
  FeatureBarRooftop: 'FeatureBarRooftop',
  FeatureBarRooftop2: 'FeatureBarRooftop2',
  FeatureBasketballCourt: 'FeatureBasketballCourt',
  FeatureBathroom: 'FeatureBathroom',
  FeatureBeautyRoom: 'FeatureBeautyRoom',
  FeatureBedroom: 'FeatureBedroom',
  FeatureBox: 'FeatureBox',
  FeatureBowlingAlleyArcade: 'FeatureBowlingAlleyArcade',
  FeatureCardsRoom: 'FeatureCardsRoom',
  FeatureCinema: 'FeatureCinema',
  FeatureCommercialArea: 'FeatureCommercialArea',
  FeatureConferenceRoom: 'FeatureConferenceRoom',
  FeatureCoworking: 'FeatureCoworking',
  FeatureCrossfit: 'FeatureCrossfit',
  FeatureEnclosedCinema: 'FeatureEnclosedCinema',
  FeatureFootage: 'FeatureFootage',
  FeatureFirePits: 'FeatureFirePits',
  FeatureFullAthleticFacility: 'FeatureFullAthleticFacility',
  FeatureFullSpa: 'FeatureFullSpa',
  FeatureArmaniCasa: 'FeatureArmaniCasa',
  FeatureGameZone: 'FeatureGameZone',
  FeatureGreenArea: 'FeatureGreenArea',
  FeatureGym: 'FeatureGym',
  FeatureHOA: 'FeatureHOA',
  FeatureKidsPark: 'FeatureKidsPark',
  FeatureKidsPlayground: 'FeatureKidsPlayground',
  FeatureKidsRoom: 'FeatureKidsRoom',
  FeatureKitchen: 'FeatureKitchen',
  FeatureLaundry: 'FeatureLaundry',
  FeatureLibraryTools: 'FeatureLibraryTools',
  FeatureLobby: 'FeatureLobby',
  FeatureLoungeArea: 'FeatureLoungeArea',
  FeatureLoungeLibrary: 'FeatureLoungeLibrary',
  FeatureMarket: 'FeatureMarket',
  FeatureMassageRoom: 'FeatureMassageRoom',
  FeatureMinigolf: 'FeatureMinigolf',
  FeatureMultifunctionRoom: 'FeatureMultifunctionRoom',
  FeatureMultipurposeCourt: 'FeatureMultipurposeCourt',
  FeatureObservationDeck: 'FeatureObservationDeck',
  FeatureOpenGarden: 'FeatureOpenGarden',
  FeatureOutdoorTheater: 'FeatureOutdoorTheater',
  FeaturePadelCourt: 'FeaturePadelCourt',
  FeaturePadlock: 'FeaturePadlock',
  FeaturePanoramicSpa: 'FeaturePanoramicSpa',
  FeatureParking: 'FeatureParking',
  FeaturePartyRoom: 'FeaturePartyRoom',
  FeaturePetShop: 'FeaturePetShop',
  FeaturePetZone: 'FeaturePetZone',
  FeaturePlayroom: 'FeaturePlayroom',
  FeaturePoolsideJuiceBar: 'FeaturePoolsideJuiceBar',
  FeaturePrivateOffice: 'FeaturePrivateOffice',
  FeatureRelaxArea: 'FeatureRelaxArea',
  FeatureRestaurant: 'FeatureRestaurant',
  FeatureSecurity: 'FeatureSecurity',
  FeatureServiceBath: 'FeatureServiceBath',
  FeatureServiceRoom: 'FeatureServiceRoom',
  FeatureServices: 'FeatureServices',
  FeatureSocialDen: 'FeatureSocialDen',
  FeatureSocialOutdoorKitchen: 'FeatureSocialOutdoorKitchen',
  FeatureSolarium: 'FeatureSolarium',
  FeatureSteamRooms: 'FeatureSteamRooms',
  FeatureStudyRoom: 'FeatureStudyRoom',
  FeatureSushi: 'FeatureSushi',
  FeatureSwimmingPool: 'FeatureSwimmingPool',
  FeatureTennisCourt: 'FeatureTennisCourt',
  FeatureTerrace: 'FeatureTerrace',
  FeatureKidsClub: 'FeatureKidsClub',
  FeatureThoughtSharingLab: 'FeatureThoughtSharingLab',
  FeatureValetParking: 'FeatureValetParking',
  FeatureWiFi: 'FeatureWiFi',
  FeatureYoga: 'FeatureYoga',
  FeatureChair: 'FeatureChair',
  FeaturePerson: 'FeaturePerson',
  FeatureSocialKitchen: 'FeatureSocialKitchen',
  FeatureCarLobby: 'FeatureCarLobby',
  FeatureMuroEscalar: 'FeatureMuroEscalar',
  FeatureBicicletero: 'FeatureBicicletero',
  FeatureZonaWellness: 'FeatureZonaWellness',
  LegacyTour360: 'LegacyTour360',
  UIAmenities: 'UIAmenities',
  UIAreas: 'UIAreas',
  UIBack: 'UIBack',
  UIChevronLeft: 'UIChevronLeft',
  UIChevronRight: 'UIChevronRight',
  UIChevronTop: 'UIChevronTop',
  UIChevronBottom: 'UIChevronBottom',
  UIClose: 'UIClose',
  UIEmail: 'UIEmail',
  UIFacebook: 'UIFacebook',
  UIFloors: 'UIFloors',
  UIGallery: 'UIGallery',
  UIHome: 'UIHome',
  UIInstagram: 'UIInstagram',
  UIAerialView: 'UIAerialView',
  UIIntro: 'UIIntro',
  UILocation: 'UILocation',
  UIMenu: 'UIMenu',
  UIMaximize: 'UIMaximize',
  UIPhone: 'UIPhone',
  UIMinimize: 'UIMinimize',
  UIOptionList: 'UIOptionList',
  UIShare: 'UIShare',
  UIWebsite: 'UIWebsite',
  UIWhatsapp: 'UIWhatsapp',
  UIZoomIn: 'UIZoomIn',
  UIZoomOut: 'UIZoomOut',
  UILink: 'UILink',
  UIDropdown: 'UIDropdown',
  UIDownload: 'UIDownload',
  UIViewDetails: 'UIViewDetails',
  UIInteractiveMap: 'UIInteractiveMap',
  UIVideo: 'UIVideo',
  UICube3D: 'UICube3D',
  UICamera: 'UICamera',
  UILayout: 'UILayout',
  UIDollar: 'UIDollar',
}

const handler = {
  get: (iconNamesObject, iconNameKey) => {
    const iconNameValue = iconNamesObject[iconNameKey]
    if (iconNameValue === undefined) {
      throw new Error(`The "${iconNameKey}" icon is undefined.`)
    }
    return iconNameValue
  },
  set: () => {
    throw new Error(`iconNames is read-only.`)
  },
}

export default new Proxy(iconNames, handler)
