import iconNames from '@/constants/ui-kit/standard/icon-names'
import IconFeatureArea from '@/components/UIKit/Standard/Icons/IconFeatureArea'
import IconFeatureAreaExternal from '@/components/UIKit/Standard/Icons/IconFeatureAreaExternal'
import IconFeatureAreaInternal from '@/components/UIKit/Standard/Icons/IconFeatureAreaInternal'
import IconFeatureAreaTotal from '@/components/UIKit/Standard/Icons/IconFeatureAreaTotal'
import IconFeatureBalcony from '@/components/UIKit/Standard/Icons/IconFeatureBalcony'
import IconFeatureBanquetDiningSpace from '@/components/UIKit/Standard/Icons/IconFeatureBanquetDiningSpace'
import IconFeatureBar from '@/components/UIKit/Standard/Icons/IconFeatureBar'
import IconFeatureBarbecue from '@/components/UIKit/Standard/Icons/IconFeatureBarbecue'
import IconFeatureBarRooftop from '@/components/UIKit/Standard/Icons/IconFeatureBarRooftop'
import IconFeatureBarRooftop2 from '@/components/UIKit/Standard/Icons/IconFeatureBarRooftop2'
import IconFeatureBasketballCourt from '@/components/UIKit/Standard/Icons/IconFeatureBasketballCourt'
import IconFeatureBathroom from '@/components/UIKit/Standard/Icons/IconFeatureBathroom'
import IconFeatureBeautyRoom from '@/components/UIKit/Standard/Icons/IconFeatureBeautyRoom'
import IconFeatureBedroom from '@/components/UIKit/Standard/Icons/IconFeatureBedroom'
import IconFeatureBox from '@/components/UIKit/Standard/Icons/IconFeatureBox'
import IconFeatureBowlingAlleyArcade from '@/components/UIKit/Standard/Icons/IconFeatureBowlingAlleyArcade'
import IconFeatureCardsRoom from '@/components/UIKit/Standard/Icons/IconFeatureCardsRoom'
import IconFeatureCinema from '@/components/UIKit/Standard/Icons/IconFeatureCinema'
import IconFeatureCommercialArea from '@/components/UIKit/Standard/Icons/IconFeatureCommercialArea'
import IconFeatureConferenceRoom from '@/components/UIKit/Standard/Icons/IconFeatureConferenceRoom'
import IconFeatureCoworking from '@/components/UIKit/Standard/Icons/IconFeatureCoworking'
import IconFeatureCrossfit from '@/components/UIKit/Standard/Icons/IconFeatureCrossfit'
import IconFeatureEnclosedCinema from '@/components/UIKit/Standard/Icons/IconFeatureEnclosedCinema'
import IconFeatureFirePits from '@/components/UIKit/Standard/Icons/IconFeatureFirePits'
import IconFeatureFootage from '@/components/UIKit/Standard/Icons/IconFeatureFootage'
import IconFeatureFullAthleticFacility from '@/components/UIKit/Standard/Icons/IconFeatureFullAthleticFacility'
import IconFeatureFullSpa from '@/components/UIKit/Standard/Icons/IconFeatureFullSpa'
import IconFeatureArmaniCasa from '@/components/UIKit/Standard/Icons/IconFeatureArmaniCasa'
import IconFeatureGameZone from '@/components/UIKit/Standard/Icons/IconFeatureGameZone'
import IconFeatureGreenArea from '@/components/UIKit/Standard/Icons/IconFeatureGreenArea'
import IconFeatureGym from '@/components/UIKit/Standard/Icons/IconFeatureGym'
import IconFeatureHOA from '@/components/UIKit/Standard/Icons/IconFeatureHOA'
import IconFeatureKidsPark from '@/components/UIKit/Standard/Icons/IconFeatureKidsPark'
import IconFeatureKidsPlayground from '@/components/UIKit/Standard/Icons/IconFeatureKidsPlayground'
import IconFeatureKidsRoom from '@/components/UIKit/Standard/Icons/IconFeatureKidsRoom'
import IconFeatureKitchen from '@/components/UIKit/Standard/Icons/IconFeatureKitchen'
import IconFeatureLaundry from '@/components/UIKit/Standard/Icons/IconFeatureLaundry'
import IconFeatureLibraryTools from '@/components/UIKit/Standard/Icons/IconFeatureLibraryTools'
import IconFeatureLobby from '@/components/UIKit/Standard/Icons/IconFeatureLobby'
import IconFeatureLoungeArea from '@/components/UIKit/Standard/Icons/IconFeatureLoungeArea'
import IconFeatureLoungeLibrary from '@/components/UIKit/Standard/Icons/IconFeatureLoungeLibrary'
import IconFeatureMarket from '@/components/UIKit/Standard/Icons/IconFeatureMarket'
import IconFeatureMassageRoom from '@/components/UIKit/Standard/Icons/IconFeatureMassageRoom'
import IconFeatureMinigolf from '@/components/UIKit/Standard/Icons/IconFeatureMinigolf'
import IconFeatureMultifunctionRoom from '@/components/UIKit/Standard/Icons/IconFeatureMultifunctionRoom'
import IconFeatureMultipurposeCourt from '@/components/UIKit/Standard/Icons/IconFeatureMultipurposeCourt'
import IconFeatureObservationDeck from '@/components/UIKit/Standard/Icons/IconFeatureObservationDeck'
import IconFeatureOpenGarden from '@/components/UIKit/Standard/Icons/IconFeatureOpenGarden'
import IconFeatureOutdoorTheater from '@/components/UIKit/Standard/Icons/IconFeatureOutdoorTheater'
import IconFeaturePadelCourt from '@/components/UIKit/Standard/Icons/IconFeaturePadelCourt'
import IconFeaturePadlock from '@/components/UIKit/Standard/Icons/IconFeaturePadlock'
import IconFeaturePanoramicSpa from '@/components/UIKit/Standard/Icons/IconFeaturePanoramicSpa'
import IconFeatureParking from '@/components/UIKit/Standard/Icons/IconFeatureParking'
import IconFeaturePartyRoom from '@/components/UIKit/Standard/Icons/IconFeaturePartyRoom'
import IconFeaturePetShop from '@/components/UIKit/Standard/Icons/IconFeaturePetShop'
import IconFeaturePetZone from '@/components/UIKit/Standard/Icons/IconFeaturePetZone'
import IconFeaturePlayroom from '@/components/UIKit/Standard/Icons/IconFeaturePlayroom'
import IconFeaturePoolsideJuiceBar from '@/components/UIKit/Standard/Icons/IconFeaturePoolsideJuiceBar'
import IconFeaturePrivateOffice from '@/components/UIKit/Standard/Icons/IconFeaturePrivateOffice'
import IconFeatureRelaxArea from '@/components/UIKit/Standard/Icons/IconFeatureRelaxArea'
import IconFeatureRestaurant from '@/components/UIKit/Standard/Icons/IconFeatureRestaurant'
import IconFeatureSecurity from '@/components/UIKit/Standard/Icons/IconFeatureSecurity'
import IconFeatureServiceBath from '@/components/UIKit/Standard/Icons/IconFeatureServiceBath'
import IconFeatureServiceRoom from '@/components/UIKit/Standard/Icons/IconFeatureServiceRoom'
import IconFeatureServices from '@/components/UIKit/Standard/Icons/IconFeatureServices'
import IconFeatureSocialDen from '@/components/UIKit/Standard/Icons/IconFeatureSocialDen'
import IconFeatureSocialOutdoorKitchen from '@/components/UIKit/Standard/Icons/IconFeatureSocialOutdoorKitchen'
import IconFeatureSolarium from '@/components/UIKit/Standard/Icons/IconFeatureSolarium'
import IconFeatureSteamRooms from '@/components/UIKit/Standard/Icons/IconFeatureSteamRooms'
import IconFeatureStudyRoom from '@/components/UIKit/Standard/Icons/IconFeatureStudyRoom'
import IconFeatureSushi from '@/components/UIKit/Standard/Icons/IconFeatureSushi'
import IconFeatureSwimmingPool from '@/components/UIKit/Standard/Icons/IconFeatureSwimmingPool'
import IconFeatureTennisCourt from '@/components/UIKit/Standard/Icons/IconFeatureTennisCourt'
import IconFeatureTerrace from '@/components/UIKit/Standard/Icons/IconFeatureTerrace'
import IconFeatureKidsClub from '@/components/UIKit/Standard/Icons/IconFeatureKidsClub'
import IconFeatureThoughtSharingLab from '@/components/UIKit/Standard/Icons/IconFeatureThoughtSharingLab'
import IconFeatureValetParking from '@/components/UIKit/Standard/Icons/IconFeatureValetParking'
import IconFeatureWiFi from '@/components/UIKit/Standard/Icons/IconFeatureWiFi'
import IconFeatureYoga from '@/components/UIKit/Standard/Icons/IconFeatureYoga'
import IconLegacyTour360 from '@/components/UIKit/Standard/Icons/IconLegacyTour360'
import IconUIAmenities from '@/components/UIKit/Standard/Icons/IconUIAmenities'
import IconUIAreas from '@/components/UIKit/Standard/Icons/IconUIAreas'
import IconUIBack from '@/components/UIKit/Standard/Icons/IconUIBack'
import IconUIChevronLeft from '@/components/UIKit/Standard/Icons/IconUIChevronLeft'
import IconUIChevronRight from '@/components/UIKit/Standard/Icons/IconUIChevronRight'
import IconUIChevronTop from '@/components/UIKit/Standard/Icons/IconUIChevronTop'
import IconUIChevronBottom from '@/components/UIKit/Standard/Icons/IconUIChevronBottom'
import IconUIClose from '@/components/UIKit/Standard/Icons/IconUIClose'
import IconUIPhone from '@/components/UIKit/Standard/Icons/IconUIPhone'
import IconUIEmail from '@/components/UIKit/Standard/Icons/IconUIEmail'
import IconUIFacebook from '@/components/UIKit/Standard/Icons/IconUIFacebook'
import IconUIGallery from '@/components/UIKit/Standard/Icons/IconUIGallery'
import IconUIHome from '@/components/UIKit/Standard/Icons/IconUIHome'
import IconUIInstagram from '@/components/UIKit/Standard/Icons/IconUIInstagram'
import IconUIAerialView from '@/components/UIKit/Standard/Icons/IconUIAerialView'
import IconUIIntro from '@/components/UIKit/Standard/Icons/IconUIIntro'
import IconUILocation from '@/components/UIKit/Standard/Icons/IconUILocation'
import IconUIMaximize from '@/components/UIKit/Standard/Icons/IconUIMaximize'
import IconUIMenu from '@/components/UIKit/Standard/Icons/IconUIMenu'
import IconUIMinimize from '@/components/UIKit/Standard/Icons/IconUIMinimize'
import IconUIOptionList from '@/components/UIKit/Standard/Icons/IconUIOptionList'
import IconUIShare from '@/components/UIKit/Standard/Icons/IconUIShare'
import IconUIFloors from '@/components/UIKit/Standard/Icons/IconUIFloors'
import IconUIWebsite from '@/components/UIKit/Standard/Icons/IconUIWebsite'
import IconUIWhatsapp from '@/components/UIKit/Standard/Icons/IconUIWhatsapp'
import IconUIZoomIn from '@/components/UIKit/Standard/Icons/IconUIZoomIn'
import IconUIZoomOut from '@/components/UIKit/Standard/Icons/IconUIZoomOut'
import IconUILink from '@/components/UIKit/Standard/Icons/IconUILink'
import IconUIDropdown from '@/components/UIKit/Standard/Icons/IconUIDropdown'
import IconUIDownload from '@/components/UIKit/Standard/Icons/IconUIDownload'
import IconUIViewDetails from '@/components/UIKit/Standard/Icons/IconUIViewDetails'
import IconUIInteractiveMap from '@/components/UIKit/Standard/Icons/IconUIInteractiveMap'
import IconUIVideo from '@/components/UIKit/Standard/Icons/IconUIVideo'
import IconUICube3D from '@/components/UIKit/Standard/Icons/IconUICube3D'
import IconUICamera from '@/components/UIKit/Standard/Icons/IconUICamera'
import IconUILayout from '@/components/UIKit/Standard/Icons/IconUILayout'
import IconFeaturePerson from '@/components/UIKit/Standard/Icons/IconFeaturePerson'
import IconFeatureChair from '@/components/UIKit/Standard/Icons/IconFeatureChair'
import IconUIDollar from '@/components/UIKit/Standard/Icons/IconUIDollar'
import IconFeatureSocialKitchen from '@/components/UIKit/Standard/Icons/IconFeatureSocialKitchen'
import IconFeatureCarLobby from '@/components/UIKit/Standard/Icons/IconFeatureCarLobby'
import IconFeatureMuroEscalar from '@/components/UIKit/Standard/Icons/IconFeatureMuroEscalar'
import IconFeatureBicicletero from '@/components/UIKit/Standard/Icons/IconFeatureBicicletero'
import IconFeatureZonaWellness from '@/components/UIKit/Standard/Icons/IconFeatureZonaWellness'

export default {
  [iconNames.FeatureArea]: IconFeatureArea,
  [iconNames.FeatureAreaExternal]: IconFeatureAreaExternal,
  [iconNames.FeatureAreaInternal]: IconFeatureAreaInternal,
  [iconNames.FeatureAreaTotal]: IconFeatureAreaTotal,
  [iconNames.FeatureBalcony]: IconFeatureBalcony,
  [iconNames.FeatureBanquetDiningSpace]: IconFeatureBanquetDiningSpace,
  [iconNames.FeatureBar]: IconFeatureBar,
  [iconNames.FeatureBarbecue]: IconFeatureBarbecue,
  [iconNames.FeatureBarRooftop]: IconFeatureBarRooftop,
  [iconNames.FeatureBarRooftop2]: IconFeatureBarRooftop2,
  [iconNames.FeatureBasketballCourt]: IconFeatureBasketballCourt,
  [iconNames.FeatureBathroom]: IconFeatureBathroom,
  [iconNames.FeatureBeautyRoom]: IconFeatureBeautyRoom,
  [iconNames.FeatureBedroom]: IconFeatureBedroom,
  [iconNames.FeatureBox]: IconFeatureBox,
  [iconNames.FeatureBowlingAlleyArcade]: IconFeatureBowlingAlleyArcade,
  [iconNames.FeatureCardsRoom]: IconFeatureCardsRoom,
  [iconNames.FeatureCinema]: IconFeatureCinema,
  [iconNames.FeatureCommercialArea]: IconFeatureCommercialArea,
  [iconNames.FeatureConferenceRoom]: IconFeatureConferenceRoom,
  [iconNames.FeatureCoworking]: IconFeatureCoworking,
  [iconNames.FeatureCrossfit]: IconFeatureCrossfit,
  [iconNames.FeatureEnclosedCinema]: IconFeatureEnclosedCinema,
  [iconNames.FeatureFootage]: IconFeatureFootage,
  [iconNames.FeatureFirePits]: IconFeatureFirePits,
  [iconNames.FeatureFullAthleticFacility]: IconFeatureFullAthleticFacility,
  [iconNames.FeatureFullSpa]: IconFeatureFullSpa,
  [iconNames.FeatureArmaniCasa]: IconFeatureArmaniCasa,
  [iconNames.FeatureGameZone]: IconFeatureGameZone,
  [iconNames.FeatureGreenArea]: IconFeatureGreenArea,
  [iconNames.FeatureGym]: IconFeatureGym,
  [iconNames.FeatureHOA]: IconFeatureHOA,
  [iconNames.FeatureKidsPark]: IconFeatureKidsPark,
  [iconNames.FeatureKidsPlayground]: IconFeatureKidsPlayground,
  [iconNames.FeatureKidsRoom]: IconFeatureKidsRoom,
  [iconNames.FeatureKitchen]: IconFeatureKitchen,
  [iconNames.FeatureLaundry]: IconFeatureLaundry,
  [iconNames.FeatureLibraryTools]: IconFeatureLibraryTools,
  [iconNames.FeatureLobby]: IconFeatureLobby,
  [iconNames.FeatureLoungeArea]: IconFeatureLoungeArea,
  [iconNames.FeatureLoungeLibrary]: IconFeatureLoungeLibrary,
  [iconNames.FeatureMarket]: IconFeatureMarket,
  [iconNames.FeatureMassageRoom]: IconFeatureMassageRoom,
  [iconNames.FeatureMinigolf]: IconFeatureMinigolf,
  [iconNames.FeatureMultifunctionRoom]: IconFeatureMultifunctionRoom,
  [iconNames.FeatureMultipurposeCourt]: IconFeatureMultipurposeCourt,
  [iconNames.FeatureObservationDeck]: IconFeatureObservationDeck,
  [iconNames.FeatureOpenGarden]: IconFeatureOpenGarden,
  [iconNames.FeatureOutdoorTheater]: IconFeatureOutdoorTheater,
  [iconNames.FeaturePadelCourt]: IconFeaturePadelCourt,
  [iconNames.FeaturePadlock]: IconFeaturePadlock,
  [iconNames.FeaturePanoramicSpa]: IconFeaturePanoramicSpa,
  [iconNames.FeatureParking]: IconFeatureParking,
  [iconNames.FeaturePartyRoom]: IconFeaturePartyRoom,
  [iconNames.FeaturePetShop]: IconFeaturePetShop,
  [iconNames.FeaturePetZone]: IconFeaturePetZone,
  [iconNames.FeaturePlayroom]: IconFeaturePlayroom,
  [iconNames.FeaturePoolsideJuiceBar]: IconFeaturePoolsideJuiceBar,
  [iconNames.FeaturePrivateOffice]: IconFeaturePrivateOffice,
  [iconNames.FeatureRelaxArea]: IconFeatureRelaxArea,
  [iconNames.FeatureRestaurant]: IconFeatureRestaurant,
  [iconNames.FeatureSecurity]: IconFeatureSecurity,
  [iconNames.FeatureServiceBath]: IconFeatureServiceBath,
  [iconNames.FeatureServiceRoom]: IconFeatureServiceRoom,
  [iconNames.FeatureServices]: IconFeatureServices,
  [iconNames.FeatureSocialDen]: IconFeatureSocialDen,
  [iconNames.FeatureSocialOutdoorKitchen]: IconFeatureSocialOutdoorKitchen,
  [iconNames.FeatureSolarium]: IconFeatureSolarium,
  [iconNames.FeatureSteamRooms]: IconFeatureSteamRooms,
  [iconNames.FeatureStudyRoom]: IconFeatureStudyRoom,
  [iconNames.FeatureSushi]: IconFeatureSushi,
  [iconNames.FeatureSwimmingPool]: IconFeatureSwimmingPool,
  [iconNames.FeatureTennisCourt]: IconFeatureTennisCourt,
  [iconNames.FeatureTerrace]: IconFeatureTerrace,
  [iconNames.FeatureKidsClub]: IconFeatureKidsClub,
  [iconNames.FeatureThoughtSharingLab]: IconFeatureThoughtSharingLab,
  [iconNames.FeatureValetParking]: IconFeatureValetParking,
  [iconNames.FeatureWiFi]: IconFeatureWiFi,
  [iconNames.FeatureYoga]: IconFeatureYoga,
  [iconNames.FeaturePerson]: IconFeaturePerson,
  [iconNames.FeatureChair]: IconFeatureChair,
  [iconNames.FeatureSocialKitchen]: IconFeatureSocialKitchen,
  [iconNames.FeatureCarLobby]: IconFeatureCarLobby,
  [iconNames.FeatureMuroEscalar]: IconFeatureMuroEscalar,
  [iconNames.FeatureBicicletero]: IconFeatureBicicletero,
  [iconNames.FeatureZonaWellness]: IconFeatureZonaWellness,
  [iconNames.LegacyTour360]: IconLegacyTour360,
  [iconNames.UIAmenities]: IconUIAmenities,
  [iconNames.UIAreas]: IconUIAreas,
  [iconNames.UIBack]: IconUIBack,
  [iconNames.UIChevronLeft]: IconUIChevronLeft,
  [iconNames.UIChevronRight]: IconUIChevronRight,
  [iconNames.UIChevronTop]: IconUIChevronTop,
  [iconNames.UIChevronBottom]: IconUIChevronBottom,
  [iconNames.UIClose]: IconUIClose,
  [iconNames.UIEmail]: IconUIEmail,
  [iconNames.UIFacebook]: IconUIFacebook,
  [iconNames.UIFloors]: IconUIFloors,
  [iconNames.UIGallery]: IconUIGallery,
  [iconNames.UIHome]: IconUIHome,
  [iconNames.UIInstagram]: IconUIInstagram,
  [iconNames.UIAerialView]: IconUIAerialView,
  [iconNames.UIIntro]: IconUIIntro,
  [iconNames.UILocation]: IconUILocation,
  [iconNames.UIMenu]: IconUIMenu,
  [iconNames.UIMaximize]: IconUIMaximize,
  [iconNames.UIPhone]: IconUIPhone,
  [iconNames.UIMinimize]: IconUIMinimize,
  [iconNames.UIShare]: IconUIShare,
  [iconNames.UIOptionList]: IconUIOptionList,
  [iconNames.UIWebsite]: IconUIWebsite,
  [iconNames.UIWhatsapp]: IconUIWhatsapp,
  [iconNames.UIZoomIn]: IconUIZoomIn,
  [iconNames.UIZoomOut]: IconUIZoomOut,
  [iconNames.UILink]: IconUILink,
  [iconNames.UIDropdown]: IconUIDropdown,
  [iconNames.UIDownload]: IconUIDownload,
  [iconNames.UIViewDetails]: IconUIViewDetails,
  [iconNames.UIInteractiveMap]: IconUIInteractiveMap,
  [iconNames.UIVideo]: IconUIVideo,
  [iconNames.UICube3D]: IconUICube3D,
  [iconNames.UICamera]: IconUICamera,
  [iconNames.UILayout]: IconUILayout,
  [iconNames.UIDollar]: IconUIDollar,
}
